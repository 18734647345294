<template>
  <v-form
    ref="form"
    lazy-validation
    @submit.prevent="onSubmit"
    class="app-form mt-4"
  >
    <h4 class="app-stepper-title">Escolha um veículo para continuar</h4>

    <v-row no-gutters>
      <v-col md="10" sm="10" xs="10">
        <v-text-field
          :loading="searchVeiculosForm.isLoading"
          item-text="veiculo.placa"
          return-object
          label="Placa"
          placeholder="Digite a placa do veículo para pesquisar"
          prepend-inner-icon="directions_bus"
          outlined
          clearable
          color="success"
          v-model="searchVeiculosForm.placa"
          v-mask="'AAA#X##'"
        ></v-text-field>
      </v-col>
      <v-col md="2" sm="2" xs="2">
        <v-btn
          depressed
          color="success"
          type="button"
          large
          block
          x-large
          class="ml-2"
        >
          <v-icon>search</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <ul class="veiculos-list" v-if="veiculos && veiculos.length">
      <li
        class="veiculos-list__item"
        v-for="(item, index) in veiculos"
        :key="index"
      >
        <label class="veiculos-list__content" :for="`veiculo-${item.id}`">
          <input
            type="radio"
            name="veiculo"
            v-model="veiculoForm.veiculoTransportadora"
            :value="item"
            :id="`veiculo-${item.id}`"
          />
          <v-icon class="veiculos-list__icon">directions_bus_filled</v-icon>
          <span class="veiculos-list__placa">{{ item.veiculo.placa }}</span>
        </label>
      </li>
    </ul>
    <p v-else class="notfound">
      Nenhum registro encontrado. Faça a pesquisa pelo veículo digitando placa
      na busca acima
    </p>

    <v-divider></v-divider>
    <v-card-actions class="ml-0 mr-0" v-if="veiculoForm.veiculoTransportadora">
      <v-spacer></v-spacer>
      <v-btn color="success" depressed type="submit">Continuar</v-btn>
    </v-card-actions>
    <section
      class="loading"
      v-if="form.isLoading || searchVeiculosForm.isLoading"
    >
      <v-progress-circular indeterminate color="success"></v-progress-circular>
      <span>Enviando informações...</span>
    </section>
  </v-form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import * as _ from "lodash";

import VeiculosService from "@/services/veiculos.service";
import TipoPedidoService from "@/services/tipo-pedidos.service";
import { TipoPedido } from "@/models/tipo.enum";

export default {
  name: "RenovacaoRegistroVeiculoForm",
  data: () => ({
    searchVeiculosForm: {
      placa: null,
      isLoading: false
    },
    veiculoForm: {
      typeSolicitation: null, // Renovação do Registro de Veículo
      veiculoTransportadora: null
    },
    veiculos: null,
    rules: {
      required: value => !!value || "Esse campo deve ser preenchido"
    }
  }),
  methods: {
    onSubmit() {
      const isValid = this.$refs.form.validate();

      if (isValid) {
        const { typeSolicitation, veiculoTransportadora } = this.veiculoForm;
        const body = {
          typeSolicitation,
          veiculoTransportadora: {
            id: veiculoTransportadora.id,
            veiculo: {
              placa: veiculoTransportadora.veiculo.placa
            }
          }
        };
        this.createRenovacaoRegistroVeiculo(body);
      }
    },
    clearVeiculoForm() {
      this.veiculoForm = {
        name: null,
        cnpj: null,
        email: null
      };
    },
    loadVeiculos() {
      this.searchVeiculosForm.isLoading = true;
      const query = {
        input: {
          placa: this.searchVeiculosForm.placa
            ? this.searchVeiculosForm.placa
            : "",
          chassiNumero: ""
        },
        inputPage: {
          page: 1,
          size: 50000,
          order: "name",
          descendingOrder: true
        }
      };

      VeiculosService.page(query).subscribe(
        ({ items }) => {
          this.veiculos = _.uniqBy(items, "id");
          this.searchVeiculosForm.isLoading = false;
        },
        err => {
          this.searchVeiculosForm.isLoading = false;
        }
      );
    },
    loadCurrentSolicitationType() {
      this.searchVeiculosForm.isLoading = true;
      const query = {
        input: {
          key: "RENOVACAO_REGISTRO_VEICULO"
        },
        inputPage: {
          page: 1,
          size: 1,
          order: "id",
          descendingOrder: true
        }
      };
      TipoPedidoService.page(query).subscribe(
        ({ items }) => {
          this.searchVeiculosForm.isLoading = false;
          if (items.length) {
            this.veiculoForm.typeSolicitation = items[0].id;
            this.loadVeiculos();
          }
        },
        err => {
          this.searchVeiculosForm.isLoading = false;
        }
      );
    },
    ...mapActions("renovacaoRegistroVeiculo", {
      createRenovacaoRegistroVeiculo: "createRenovacaoRegistroVeiculo"
    })
  },
  computed: {
    ...mapState("renovacaoRegistroVeiculo", ["form"])
  },
  watch: {
    // verifica se muda o step para limpar os campos do form
    "form.currentStep": function(newValue, oldValue) {
      if (newValue) {
        this.clearVeiculoForm();
      }
    }
  },
  mounted() {
    this.loadCurrentSolicitationType();
  }
};
</script>

<style lang="scss">
.veiculos-list {
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  flex-wrap: wrap;
  flex-direction: row;

  &__item {
    padding: 0px 5px;
    flex: 1;
    cursor: pointer;
    width: 100%;
    margin-bottom: 15px;
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #999;
    cursor: pointer;

    &:hover,
    &--active {
      background: #067b26;
      color: #fff;
      .veiculos-list__icon {
        color: #fff;
      }
    }
  }

  &__placa {
    text-align: center;
    font-weight: bold;
  }
}
.notfound {
  text-align: center;
  margin: 0px;
}
</style>
